html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Source Sans Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
[data-reach-tab-list] {
  background-color: transparent;
  border-bottom: 1px solid #ccc;
}
[data-reach-tab] {
  margin-bottom: -1px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
[data-reach-tab]:disabled {
  opacity: 0.5;
}

[data-selected] {
  background-color: #3c618b;
  color: #fff;
  border-bottom: none;
}

[data-reach-tab-panel]:focus {
  outline: none;
}
